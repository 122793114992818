import { useEffect, useState } from 'react';

const HeaderComponentLastUpdated = (props: any) => {
    const data = props;
    const [sortState, setSortState] = useState('');
    const handleSort = () => {
        let newSortState;
        if (sortState === '') {
            newSortState = 'asc';
        }
        else if (sortState === 'asc') {
            newSortState = 'desc';
        } else {
            newSortState = '';
        }
        setSortState(newSortState);
        props.setSort(newSortState, true);
    };
    useEffect(() => {
        const onSortChanged = () => {
            const allColumns = props.columnApi.getAllColumns();
            let isSorted = false;
            allColumns.forEach((col: any) => {
                if (col.getSort()) {
                    isSorted = true;
                }
            });

            const direction = props.column.getSort();
            setSortState(isSorted && direction ? direction : '');
        };

        props.column.addEventListener('sortChanged', onSortChanged);

        onSortChanged(); // Initial sort state

        return () => {
            props.column.removeEventListener('sortChanged', onSortChanged);
        };
    }, [props.column, props.columnApi]);
    return (
        <>
            <div className='col-md-12'>
                <div className='row'>
                <div className='col-md-7 d-flex' onClick={handleSort} style={{ cursor: 'pointer' }}>
                    <span>{props.displayName}</span>
                    <span className={`sort-padding ${sortState === 'asc' ? 'ag-icon ag-icon-asc' : sortState === 'desc' ? 'ag-icon ag-icon-desc' : ''}`}>
                    </span>
                </div>
                    <div className='d-flex justify-content-end col-md-5'>
                    <span id="lastUpdatedAssign"> Last Updated </span>
                </div>
            </div>
            </div >
        </>
    );
};
export default HeaderComponentLastUpdated;