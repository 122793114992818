import { RequestItemsDetails } from "dto/requestItemsDetails";

const requestItemsDetailsData: RequestItemsDetails = {
  jobAssignmentPropertyId: 284386,
  requestDetailsMessage: "Please upload each requested item listed below.",
  contactsDetail: [
    {
      id: 53,
      email: "first.last@nmrk.com",
      name: "name",
      phone: "phone",
    },
  ],
  leadAppraisersDetail: [],
  documentsDetail: [],
  commentsDetail: [],
  clientContactIds: [],
  assignmentContactIds: [],
  assignmentDocumentIds: [],
  assignmentCommentIds: [],
  requestedItems: [
    {
      id: 689484,
      requestItemTypeId: 1,
      requestItemTypeDescription: "Is Newmark the Broker or Property Manager?",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 10,
    },
    {
      id: 689485,
      requestItemTypeId: 2,
      requestItemTypeDescription: "Floorplans",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 20,
    },
    {
      id: 689486,
      requestItemTypeId: 3,
      requestItemTypeDescription: "Site Plan",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 30,
    },
    {
      id: 689487,
      requestItemTypeId: 6,
      requestItemTypeDescription: "Contact details for inspection",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 60,
    },
    {
      id: 689490,
      requestItemTypeId: 9,
      requestItemTypeDescription: "Construction costs",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 70,
    },
    {
      id: 689492,
      requestItemTypeId: 11,
      requestItemTypeDescription:
        "List of major repairs and improvements needed",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 80,
    },
    {
      id: 689493,
      requestItemTypeId: 12,
      requestItemTypeDescription: "Three-year history of capital improvements",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 90,
    },
    {
      id: 689494,
      requestItemTypeId: 21,
      requestItemTypeDescription: "Rent Roll",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 110,
    },
    {
      id: 689495,
      requestItemTypeId: 22,
      requestItemTypeDescription: "Copies of leases",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 120,
    },
    {
      id: 689499,
      requestItemTypeId: 26,
      requestItemTypeDescription: "Occupancy rates history",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 130,
    },
    {
      id: 689501,
      requestItemTypeId: 31,
      requestItemTypeDescription: "Ground leases",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 150,
    },
    {
      id: 689502,
      requestItemTypeId: 33,
      requestItemTypeDescription: "ESG - Building certification(s)",
      requestItemCategoryTypeId: 3,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 160,
    },
    {
      id: 689505,
      requestItemTypeId: 36,
      requestItemTypeDescription: "Property offering details",
      requestItemCategoryTypeId: 4,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 170,
    },
    {
      id: 689506,
      requestItemTypeId: 37,
      requestItemTypeDescription: "Offers or pending contract",
      requestItemCategoryTypeId: 4,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 180,
    },
    {
      id: 689507,
      requestItemTypeId: 39,
      requestItemTypeDescription:
        "Letters of intent to lease or lease proposals",
      requestItemCategoryTypeId: 4,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 190,
    },
    {
      id: 689511,
      requestItemTypeId: 45,
      requestItemTypeDescription: "Income and expense statements",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 200,
    },
    {
      id: 689512,
      requestItemTypeId: 46,
      requestItemTypeDescription: "Operating budgets",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 210,
    },
    {
      id: 689515,
      requestItemTypeId: 49,
      requestItemTypeDescription:
        "Real estate tax bills, assessments, litigation, etc.",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 220,
    },
    {
      id: 689516,
      requestItemTypeId: 52,
      requestItemTypeDescription: "Prior Argus or VAL files, if any",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 240,
    },
    {
      id: 689519,
      requestItemTypeId: 55,
      requestItemTypeDescription: "Expense reimbursements/base year expenses",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 1,
      requestItemDisplaySortOrder: 250,
    },
    {
      id: 689488,
      requestItemTypeId: 7,
      requestItemTypeDescription: "Engineering, or property condition reports",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 280,
    },
    {
      id: 689489,
      requestItemTypeId: 8,
      requestItemTypeDescription:
        "Feasibility, market, and economic impact studies",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 290,
    },
    {
      id: 689491,
      requestItemTypeId: 10,
      requestItemTypeDescription: "Environmental audits and studies",
      requestItemCategoryTypeId: 1,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 300,
    },
    {
      id: 689496,
      requestItemTypeId: 23,
      requestItemTypeDescription: "Title report",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 370,
    },
    {
      id: 689497,
      requestItemTypeId: 24,
      requestItemTypeDescription: "Legal description or copy of deed",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 380,
    },
    {
      id: 689498,
      requestItemTypeId: 25,
      requestItemTypeDescription:
        "Deed restrictions or encumbrances, easements",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 390,
    },
    {
      id: 689500,
      requestItemTypeId: 30,
      requestItemTypeDescription:
        "LURA, affordable, or restricted status of units",
      requestItemCategoryTypeId: 2,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 420,
    },
    {
      id: 689503,
      requestItemTypeId: 34,
      requestItemTypeDescription: "ESG - Energy Star Rating",
      requestItemCategoryTypeId: 3,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 430,
    },
    {
      id: 689504,
      requestItemTypeId: 35,
      requestItemTypeDescription: "ESG - Energy Use Intensity",
      requestItemCategoryTypeId: 3,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 440,
    },
    {
      id: 689508,
      requestItemTypeId: 40,
      requestItemTypeDescription: "Marketing/sales/leasing contact details",
      requestItemCategoryTypeId: 4,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 460,
    },
    {
      id: 689509,
      requestItemTypeId: 41,
      requestItemTypeDescription: "Copy of prior appraisal",
      requestItemCategoryTypeId: 4,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 470,
    },
    {
      id: 689510,
      requestItemTypeId: 43,
      requestItemTypeDescription: "Other market or comparable data",
      requestItemCategoryTypeId: 4,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 490,
    },
    {
      id: 689513,
      requestItemTypeId: 47,
      requestItemTypeDescription: "Insurance Premium Binders",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 510,
    },
    {
      id: 689514,
      requestItemTypeId: 48,
      requestItemTypeDescription: "Management contracts",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 520,
    },
    {
      id: 689517,
      requestItemTypeId: 53,
      requestItemTypeDescription: "Outstanding broker leasing commissions",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 540,
    },
    {
      id: 689518,
      requestItemTypeId: 54,
      requestItemTypeDescription:
        "Major tenant financials or credit report/ratings",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 550,
    },
    {
      id: 689520,
      requestItemTypeId: 68,
      requestItemTypeDescription: "Pending litigation or negotiations",
      requestItemCategoryTypeId: 5,
      requestItemStatusTypeId: 1,
      contacts: [
        {
          contactId: 53,
          canReassign: true,
        },
      ],
      documentIds: [],
      commentIds: [],
      requestItemSortOrderGroupTypeId: 2,
      requestItemDisplaySortOrder: 660,
    },
  ],
};

export { requestItemsDetailsData };
