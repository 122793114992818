import { AxiosResponse } from "axios";

import { Identity } from "dto/identity";
import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import DATA_PROVIDERS from "enums/dataProviders";

async function _performDataOp(
  identity: Identity,
  dataOp: DATA_OPERATIONS,
  {
    file,
    fileName,
    fileExtension,
    jobAssignmentPropertyId,
    id,
    requestItemStatusTypeId,
    contactDetailEmail,
    contactDetailName,
    contactDetailPhone,
  }: {
    file?: any;
    fileName?: string;
    fileExtension?: string;
    jobAssignmentPropertyId?: number;
    id?: number;
    requestItemStatusTypeId?: number;
    contactDetailEmail?: string;
    contactDetailName?: string;
      contactDetailPhone?: string | null;
    } = {},
  progressCallback?: (event: any) => void

): Promise<any> {
  let data;
  switch (dataOp) {
    case DATA_OPERATIONS.CREATE:
      data = {
        file,
        fileName,
        fileExtension,
        jobAssignmentPropertyId,
        id,
      };
      break;
    case DATA_OPERATIONS.UPDATE:
      data = {
        id,
        requestItemStatusTypeId,
        contactDetailEmail,
        contactDetailName,
        contactDetailPhone,
      };
      break;
  }
  const response: AxiosResponse = await makeHttpCall(
    identity,
    dataOp,
    process.env.REACT_APP_APIM_DOCUMENT!,
    {
      data,
      dataProvider: DATA_PROVIDERS.VALUATIONS,
      progressCallback
    }
  );
  if (response?.status < 300) {
    return response.data;
  }
  return {};
}

export async function createDocument(
  identity: Identity,
  data: {
    file: any;
    fileName: string;
    fileExtension: string;
    jobAssignmentPropertyId: number;
    id?: number;
  },
  progressCallback?: (event: any) => void

): Promise<any> {
  return await _performDataOp(identity, DATA_OPERATIONS.CREATE, data, progressCallback);
}

export async function updateDocument(
  identity: Identity,
  data: {
    id: number;
    requestItemStatusTypeId?: number;
    contactDetailEmail?: string;
    contactDetailName?: string;
    contactDetailPhone?: string | null;
  }
) {
  return await _performDataOp(identity, DATA_OPERATIONS.UPDATE, data);
}
