import { Container, Row, Col } from "reactstrap";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";

import "./index.scss";
import Loading from "components/shared/loading";

import { useAppSelector } from "hooks";
import { selectUser } from "slices/user/userSlice";
import DATA_PROVIDERS from "enums/dataProviders";
import { selectAssignmentList } from "slices/assignment/assignmentListSlice";
import { Assignment } from "dto/assignment";
import DataGrid from "components/shared/grid";
import { AppDispatch } from "store";
import { NavigateFunction } from "react-router-dom";
import { useState } from "react";
import collapsible from "components/shared/collapsible/index.module.scss";
import HeaderComponentLastUpdated from './headerComponentLastUpdated';

type GenericProps<T extends React.HTMLAttributes<HTMLElement>> = T;

export default function ValuationsDataRoomListComponent<
  T extends React.HTMLAttributes<HTMLElement>
>(props: GenericProps<T>) {
  const { ...rest } = props;
  const user = useAppSelector(selectUser);
  const userId: string = user.id;
  const listOfAssignments: Assignment[] = useAppSelector(selectAssignmentList);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const ContactRoleTypeId: number = 1; //Client Contact
  const newAssignmentStatus = 'NEW ASSIGNMENT';

  // Function to handle expand/collapse click 
  const handleExpandCollapseClick = (assignmentId: string) => {
    setExpandedRows((prev) => ({
      ...prev, [assignmentId]: !prev[assignmentId],
    }));
  };

  const formatDate = (date: string) => {
    if (date === undefined || date === null || date === "")
      return "";
    else {
      let dateTime = new Date(date);
      const formatedDate = dateTime.toLocaleDateString();
      return formatedDate;
    }
  };

  const columnDefs: ColDef[] = [
    {
      field: 'expandCollapse',
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      cellClass: 'padding-expandCollapse',
      cellRenderer: (params: ICellRendererParams) => {
        const assignmentId = params.node.id?.toString();
        const isExpanded = assignmentId ? expandedRows[assignmentId] : false;
        const contactLevelTypeId = params.data.contactLevelTypeId;
        return (
          <>
            {contactLevelTypeId === ContactRoleTypeId &&
              <button
                aria-expanded={isExpanded}
                onClick={() => assignmentId && handleExpandCollapseClick(assignmentId)}
                className={`toggle ${isExpanded
                  ? `${collapsible.collapsible__toggle} ${collapsible.active}`
                  : `${collapsible.collapsible__toggle}`
                  }`}
              >
              </button>
            }
          </>
        );
      },
    },
    {
      field: "jobAssignmentNumber",
      headerName: "Assignment",
      minWidth: 200,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams<Assignment>) => {
        const { jobAssignmentNumber, jobAssignmentClientReference } =
          params.data!;
        return (
          <div className="light-text" style={{ lineHeight: 2 }}>
            {jobAssignmentNumber}
            {jobAssignmentClientReference && (
              <>
                <br />
                Client Ref: {jobAssignmentClientReference}
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "propertyName",
      headerName: "Property",
      headerComponent: HeaderComponentLastUpdated,
      headerComponentParams: { displayName: 'Property' },
      flex: 5,
      sortable: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams<Assignment>) => {
        const {
          propertyName,
          propertyAddress1,
          propertyCity,
          propertyState,
          propertyZip,
          propertyTypeDescription,
          emailSentOn,
          emailDeliveredOn,
          fileUploadDate,
          assignmentViewedDate,
          contactLevelType,
          contactLevelTypeId,
          appraisalReportDeliveredDate,
          propertyInspectedDate,
          saleCompAddedDate,
          rentalSurveyCompAddedDate,
          ieCompAddedDate,
          lastUpdatedDate,
          lastUpdatedStatus
        } = params.data!;

        const assignmentId = params.node.id?.toString();
        const isExpanded = assignmentId ? expandedRows[assignmentId] : false;        
        const lastUpdatedStatusFormat = lastUpdatedStatus ? lastUpdatedStatus.toUpperCase() : newAssignmentStatus;

        return (
          <div style={{ lineHeight: 2 }}>
            <Row>
              <div className="col-md-7 col-sm-4 col-lg-7">
                {propertyName}
                <div className="light-text overflow-text">{`${propertyAddress1}, ${propertyCity}, ${propertyState} ${propertyZip}`}</div>
                <div className="light-text overflow-text">{propertyTypeDescription}</div>
              </div>
              {!isExpanded && contactLevelTypeId === ContactRoleTypeId &&
                <Col>
                  <span className="light-text d-flex justify-content-end">{formatDate(lastUpdatedDate)}</span></Col>
              }
            </Row>

            {!isExpanded && contactLevelTypeId === ContactRoleTypeId &&
              <div> STATUS: <span className="status-hover">{lastUpdatedStatusFormat}</span></div>
            }
            {isExpanded && contactLevelTypeId === ContactRoleTypeId && (
              <div
                className={`pt-2 ${collapsible["collapsible__content-clipper"]}`}
              >
                <div className={`dataDiv-border-padding ${collapsible.collapsible__content} overflow-hide`}>
                  <div className="nmrk-color-blue">
                    DATA COLLECTION
                  </div>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${emailSentOn ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Email sent</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {emailSentOn ? `On ${formatDate(emailSentOn)}` : '-'}</span></Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${emailDeliveredOn ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Email delivered</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {emailDeliveredOn ? `On ${formatDate(emailDeliveredOn)}` : '-'}</span></Col>

                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${assignmentViewedDate ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Assignment viewed</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {assignmentViewedDate ? `On ${formatDate(assignmentViewedDate)}` : '-'}</span></Col>

                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${fileUploadDate ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Files uploaded </span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {fileUploadDate ? `On ${formatDate(fileUploadDate)}` : '-'}</span></Col>

                  </Row>
                  <div className="nmrk-color-blue">
                    ANALYSIS & APPRAISAL
                  </div>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${propertyInspectedDate ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Property inspected</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {propertyInspectedDate ? `On ${formatDate(propertyInspectedDate)}` : '-'}</span></Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${saleCompAddedDate ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Sale comp data added</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {saleCompAddedDate ? `On ${formatDate(saleCompAddedDate)}` : '-'}</span></Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${rentalSurveyCompAddedDate ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Rental comp data added</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {rentalSurveyCompAddedDate ? `On ${formatDate(rentalSurveyCompAddedDate)}` : '-'}</span></Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${ieCompAddedDate ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Income/Expense comp data added</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {ieCompAddedDate ? `On ${formatDate(ieCompAddedDate)}` : '-'}</span></Col>
                  </Row>
                  <Row>
                    <Col lg={7} md={7} className="d-flex">
                      <span className={`margin-top-6 ${appraisalReportDeliveredDate ? `complete-icon` : `incomplete-icon`}`}></span>
                      <span className="light-text margin-left-8">Appraisal report delivered</span></Col>
                    <Col>
                      <span className="light-text d-flex justify-content-end"> {appraisalReportDeliveredDate ? `On ${formatDate(appraisalReportDeliveredDate)}` : '-'}</span></Col>
                  </Row>
                </div>
              </div>
            )
            }
          </div>
        );
      },
    },
    // {
    //   field: "jobAssignmentDueDate",
    //   headerName: "Upload By",
    //   minWidth: 200,
    //   flex: 1,
    //   sortable: true,
    //   sort: "asc",
    //   cellRenderer: (params: ICellRendererParams<Assignment>) => {
    //     const { jobAssignmentDueDate } = params.data!;
    //     return <div>{new Date(jobAssignmentDueDate).toLocaleDateString()}</div>;
    //   },
    // },
    {
      field: "jobAssignmentLeadAppraisers",
      headerName: "Appraiser",
      flex: 2,
      sortable: true,
      autoHeight: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data !== null) {
          const {
            data: { jobAssignmentLeadAppraisers: appraisers },
          } = params;
          if (appraisers.length === 1) {
            return appraisers[0];
          } else if (appraisers.length > 1) {
            return `${appraisers[0]} +${appraisers.length - 1}`;
          }
          return "";
        }
      },
      cellRenderer: (params: ICellRendererParams<Assignment>) => {
        const { jobAssignmentLeadAppraisers } = params.data!;
        return (
          <div style={{ lineHeight: 2 }}>
            {jobAssignmentLeadAppraisers.map((appraiser) => (
              <div className="light-text">
                {appraiser}
                <br />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {!userId ? (
        <Loading withoutHeader={true} />
      ) : (
        <div {...rest}>
          <Container
            fluid
            id="valuations-data-room-list"
            className="valuations-data-room-list-parent h-100"
          >
            <Row>
              <h2 className="valuations-data-room-list-header">Valuations</h2>
            </Row>
            <Row className="valuations-data-room-list-data">
              <DataGrid
                data={listOfAssignments}
                columnDefs={columnDefs}
                navigateWrapper={(navigate: NavigateFunction, data: any) => {
                  navigate(
                    `${DATA_PROVIDERS.VALUATIONS.toLowerCase()}/dataRooms/${data.jobAssignmentPropertyId
                    }`
                  );
                }}
                dispatchWrapper={(dispatch: AppDispatch, data: any) => {
                  dispatch({
                    type: "assignmentSelected/select",
                    payload: data,
                  });
                }}
              />
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}
